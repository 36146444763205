import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([ 'activeModules' ]),
    isGamificationEnabled () {
      return this.activeModules.includes('gamification')
    },
    isWebhookEnabled () {
      return this.activeModules.includes('webhook')
    }
  },
  watch: {
    isWebhookEnabled: {
      immediate: true,
      handler (value) {
        this.tabLinks = this.tabLinks.map((tab) => {
          if (tab.location.name === 'management.webhooks') {
            tab.disabled = !value
          }

          return tab
        })
      }
    }
  },
  data () {
    return {
      tabLinks: [
        {
          location: { name: 'management.index' },
          text: this.$t('management.tabs:link.1')
        },
        {
          location: { name: 'management.user.profiles' },
          text: this.$t('management.tabs:link.2')
        },
        {
          location: { name: 'management.survey' },
          text: this.$t('management.tabs:link.4')
        },
        {
          location: { name: 'management.certificates' },
          text: this.$t('management.tabs:link.5')
        },
        {
          location: { name: 'management.banners' },
          text: this.$t('management.tabs:link.6'),
          disabled: true
        },
        {
          location: { name: 'management.gamification' },
          text: this.$t('management.tabs:link.7'),
          disabled: !this.isGamificationEnabled
        },
        {
          location: { name: 'management.settings' },
          text: this.$t('management.tabs:link.settings')
        },
        {
          location: { name: 'management.webhooks' },
          text: 'Webhooks',
          disabled: !this.isWebhookEnabled
        }
      ]
    }
  }
}
